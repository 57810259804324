import { Tooltip } from "@chakra-ui/react";
import { ProductAvailabilityOptions, PublicProduct } from "libs/data-access/src/lib/api/generated";
import useAvailability from "@gruene-brise/data-access/hooks/useAvailability";
import useCartManager from "@gruene-brise/data-access/hooks/useCartManager";
import { isEmpty, isNull } from "lodash";
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";
import AddToCartIcon from "../icon/AddToCartIcon";
import useCurrencyFormatter from "libs/data-access/src/lib/hooks/useCurrencyFormatter";
import { getCbdRange, getThcRange } from "../../../../data-access/src/lib/utils/product-details";
import { ReactComponent as IrradiationNo } from "@gruene-brise/common-ui/assets/icons/irradiation-no.svg";
import { ReactComponent as IrradiationYes } from "@gruene-brise/common-ui/assets/icons/irradiation-yes.svg";
import { ReactComponent as HybridGenetics } from "@gruene-brise/common-ui/assets/icons/genetics-hybrid.svg";
import { ReactComponent as SativaGenetics } from "@gruene-brise/common-ui/assets/icons/genetics-sativa.svg";
import { ReactComponent as IndicaGenetics } from "@gruene-brise/common-ui/assets/icons/genetics-indica.svg";
import { useAuthState } from "@gruene-brise/data-access/state/useAuthState";
import countryList from "react-select-country-list";
import Link from "next/link";
import usePrice from "@gruene-brise/data-access/hooks/usePrice";

export interface ProductCardProps extends PublicProduct {
  onPress?(): void;
  showCartButton?: boolean;
  cardWrapperClass?: string;
  productCardWrapperClass?: string;
}

/**
 * Product Card shows the design of a single product based on the figma design.
 * You will see its price, availability status, origin etc.
 *
 * @param
 * @param onPress?():void
 * @param showCartButton?: boolean;
 * @param cardWrapperClass?: string;
 * @param productCardWrapperClass?: string;
 * @returns
 */
const ProductCard = (producatCardProperties: ProductCardProps) => {
  const {
    fullName,
    description,
    metaFields,
    imageURL,
    availability,
    genetics,
    manufacturer,
    irradiation,
    strain,
    startingPrice,
    unitValue,
    startingPriceIsInsurance,
    basePrice,
    onPress,
    id,
    unit,
    showCartButton = true,
    cardWrapperClass,
    productCardWrapperClass,
    slotOrder,
  } = producatCardProperties;
  const isPromoted = Boolean(slotOrder);

  const { t } = useTranslation();
  const { userInfo } = useAuthState();
  const availabilityHook = useAvailability();
  const availabilityValues = availabilityHook(availability);
  const { calculatePrice } = usePrice();

  const getValues = (regex: any) => {
    return (metaFields ?? [])?.filter((i) => regex.test(i.name)).map((i) => i.value);
  };

  const thc = getThcRange(unit, metaFields);
  const cbd = getCbdRange(unit, metaFields);

  const countryCode = getValues(/(origin)/i)
    .join("")
    .toUpperCase();

  const getCountryName = () => {
    const country = countryList().getLabel(countryCode);
    if (country) {
      return country;
    } else {
      return "unknown";
    }
  };

  const iconClass = "text-primary-50 hover:text-primary text-primary-100";

  const irradiationIcon: Record<"yes" | "no", JSX.Element> = {
    yes: <IrradiationYes className={iconClass} />,
    no: <IrradiationNo className={iconClass} />,
  };

  const geneticsIcon: Record<string, JSX.Element> = {
    sativa: <SativaGenetics className={iconClass} />,
    indica: <IndicaGenetics className={iconClass} />,
    hybrid: <HybridGenetics className={iconClass} />,
  };

  const Irradiation =
    irradiationIcon[irradiation.name && irradiation?.name !== `${t("Unbestrahlt")}` ? "yes" : "no"];
  const Genetics =
    geneticsIcon[genetics?.name?.toLocaleLowerCase() as "sativa" | "indica" | "hybrid"];

  const productMenu = [
    {
      title: `${t("irradiation")}: ${irradiation.name ?? "N/A"}`,
      icon: Irradiation,
    },
    {
      title: `${t("genetics")}: ${genetics.name ?? "N/A"}`,
      icon: Genetics,
    },
    countryCode
      ? {
          title: t(getCountryName()),
          icon: (
            <div className='w-[25px] min-h-[20px] h-auto px-1 sm:px-0 sm:w-[30px] sm:h-[30px] overflow-hidden rounded-full bg-primary-alpha flex justify-center items-center'>
              <ReactCountryFlag
                countryCode={countryCode}
                svg
                style={{
                  width: "17px",
                  height: "17px",
                }}
              />
            </div>
          ),
        }
      : null,
  ];

  const productField = [
    {
      name: t("THC"),
      value: `${isEmpty(thc) ? "0,0" : thc}`,
    },
    {
      name: t("cbd"),
      value: `${isEmpty(cbd) ? "0,0" : cbd}`,
    },
  ];

  const showPrice = !isNull(startingPrice);
  const price = showPrice
    ? calculatePrice({ unitValue, basePrice, startingPrice, startingPriceIsInsurance }, unitValue)!
    : 0;
  const numberFormatter = useCurrencyFormatter();
  const priceFormat = numberFormatter(price);

  const { isItemAlreadyInCart } = useCartManager();
  // href={`/product/${id}`}

  return (
    <>
      <div
        className={`${productCardWrapperClass} select-none bg-white mb-20 w-4/4  md:w-[256.57px] md:mx-4  rounded-lg sm:rounded-2xl xl:rounded-2xl md:rounded-2xl font-gellix shadow-button_sm relative`}
      >
        {isPromoted ? (
          <Tooltip className='text-xs' label={t("product_card_highlighted_by_relevance_tooltip")}>
            <div className='flex -top-5	absolute w-[100%] flex justify-end pr-2 sm:pr-5 lg:pr-5 md:pr-5 xl:pr-5'>
              <div className='text-[#919C8B] text-[8pt] font-gellix'>{t("Sponsored")}</div>
            </div>
          </Tooltip>
        ) : null}
        <div
          className={`${cardWrapperClass} w-full h-full pt-1 bg-primary-10 rounded-lg sm:rounded-2xl xl:rounded-2xl md:rounded-2xl lg:rounded-2xl flex flex-col `}
        >
          <Link href={`/product/${id}`} className='flex flex-col h-full justify-between'>
            <div className='w-full flex flex-row'>
              <div className='w-[60%]  -mt-[20px] sm:-mt-[65px] xl:-mt-[65px] md:-mt-[65px] lg:-mt-[65px]'>
                {isEmpty(imageURL) ? (
                  // TODO: add placeholder image
                  <></>
                ) : (
                  <img
                    src={imageURL}
                    className='w-[80px] h-[80px] md:w-[138px] md:h-[152px] xl:w-[138px] sm:w-[138px] xl:h-[152px] sm:h-[152px] lg:w-[138px] lg:h-[152px] object-contain'
                  />
                )}
              </div>

              <div className='w-[40%] mt-1 lg:mt-0 xl:mt-0 sm:mt-0 md:mt-0 mr-2 sm:mr-5 lg:mr-5 md:mr-5 xl:mr-5 flex flex-col justify-end'>
                <div className='w-full justify-end items-center flex font-gellix'>
                  <span
                    className={`
                ${
                  availability.currentAvailability === ProductAvailabilityOptions.Unavailable
                    ? "bg-fail-alpha"
                    : availabilityValues.style.backgroundStyle
                }  ${availabilityValues.style.textStyle}
                  px-2 flex items-center text-center py-1 text-[10px] md:text-xs xl:text-xs lg:text-xs font-500 rounded-lg whitespace-nowrap`}
                  >
                    {availabilityValues.style.textValue}
                  </span>
                </div>

                <div className='flex flex-row justify-end pt-3 pb-2 gap-2'>
                  {productMenu.map((i, idx) => {
                    return (
                      i?.icon && (
                        <Tooltip
                          hasArrow
                          key={idx}
                          label={i?.title}
                          placement='bottom'
                          className='cursor-pointer rounded-lg'
                        >
                          <div className='rounded-full w-[25px] h-[25px] sm:w-[30px] sm:h-[30px] xl:w-[30px] xl:h-[30px] md:w-[30px] lg:w-[30px] md:h-[30px] lg:h-[30px] overflow-hidden justify-center items-center flex'>
                            {i?.icon}
                          </div>
                        </Tooltip>
                      )
                    );
                  })}
                </div>
              </div>
            </div>

            <div className='mb-2 mt-1 px-3 sm:px-5 md:px-5 lg:px-5 xl:px-5 w-[100%] flex flex-row items-center justify-center gap-1'>
              {productField.map((i, idx) => {
                return (
                  <div
                    key={idx}
                    className='bg-white w-[50%] py-1.5 px-2 flex items-center justify-center rounded-md font-gellix text-[10px] sm:text-sm lg:text-sm md:text-sm xl:text-sm text-center'
                  >
                    <span className='text-grey text-[8px] sm:text-[10px] mr-1'>{i.name}</span>

                    <span className='text-black font-medium whitespace-nowrap text-[8px] sm:text-xs'>
                      {i.value}
                    </span>
                  </div>
                );
              })}
            </div>

            <div
              className={`w-full flex-1 pb-1.5 px-3 lg:px-5 md:px-5 xl:px-5 sm:px-5 bg-white pt-2 sm:py-4 lg:py-4 md:py-4 xl:py-4 flex flex-col   font-gellix
            `}
            >
              <span className='font-semibold text-xs lg:text-[16px] md:text-[16px] sm:text-[16px] xl:text-[16px] text-red w-[70%] relative line-clamp-4 font-gellix'>
                {fullName}
              </span>

              <span className='w-full tracking-widest text-[7px] pt-1 sm:text-xs md:text-xs lg:text-xs xl:text-xs font-medium text-primary uppercase line-clamp-2 font-gellix'>
                {strain?.name}
              </span>
            </div>
          </Link>

          {!showPrice && (
            <div className='w-full bg-white flex flex-row justify-center py-3 sm:py-4 font-gellix rounded-b-lg xl:rounded-b-2xl sm:rounded-b-2xl lg:rounded-b-2xl md:rounded-b-2xl'>
              {Boolean(userInfo) && (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open("https://share-eu1.hsforms.com/1dsSoo1VUTjOXbmXYTvWArAg4lyh");
                  }}
                  className='flex flex-row justify-center items-center  px-4 gap-4  w-[80%] h-8 bg-opacity-10 bg-gray-500 rounded-md cursor-pointer'
                >
                  <h2 className='flex first-line:font-gellix font-medium text-xs md:text-base lg:text-base xl:text-base text-center leading-none text-green-900'>
                    {t("Price on request")}
                  </h2>
                </div>
              )}
            </div>
          )}

          {showPrice && (
            <div className='w-full pb-3 bg-white flex rounded-b-lg xl:rounded-b-2xl sm:rounded-b-2xl lg:rounded-b-2xl md:rounded-b-2xl flex-col  '>
              <div className='flex flex-row justify-between px-3 lg:px-5 md:px-5 sm:px-5 xl:px-5 items-center font-gellix'>
                <div className=''>
                  <span className='text-[7px] sm:text-xs md:text-xs xl:text-xs lg:text-xs font-light pr-1 text-grey'>
                    ab
                  </span>
                  <span className='text-[16px] md:text-xl xl:text-xl lg:text-xl font-semibold'>
                    {priceFormat}
                  </span>
                </div>
                {showCartButton && (
                  <div className=''>
                    <Tooltip
                      label={isItemAlreadyInCart(id) ? t("Item is already in cart") : undefined}
                    >
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          onPress?.();
                        }}
                        className={`w-[30px] h-[30px] sm:w-[40px] sm:h-[40px] xl:w-[40px] xl:h-[40px] md:w-[40px] md:h-[40px] lg:w-[40px] lg:h-[40px]    flex items-center justify-center rounded-full
                        hover:bg-secondary-dark transition-all duration-300 bg-secondary cursor-pointer`}
                      >
                        <AddToCartIcon />
                      </div>
                    </Tooltip>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ProductCard;
